const DLActionHandler = {
    "refsourceid": 0,
    "campaign_share": 1,
    "login": 2,
    "notification": 3,
    "chat": 4,
    "invoice": 5,
    "bidding": 6,
    "basic": 7,
    "payout": 8,
    "contentEnabled": 9,
    "creatorBasicDetails": 10,

    0: "refsourceid",
    1: "campaign_share", 
    2: "login",
    3: "notification",
    4: "chat",
    5: "invoice",
    6: "bidding",
    7: "basic",
    8: "payout",
    9: "contentEnabled",
    10: "creatorBasicDetails"
}

export default DLActionHandler