import React, { useEffect, useState } from "react";
import OTPLimiter from "../../Utils/OTPLimiter";
import { useDispatch, useSelector } from "react-redux";
import { getCreatorThunk, getOtpThunk } from "../../API";
import toast from "react-hot-toast";
import axios from "axios";
import constants from "../../constants";
import submitMobileOtp from "../../Services/apicalls/submitMobileOtp";
import ApiService from "../../Services/api.services";
import AddInstagramManually from "../../Services/apicalls/AddInstagramManually";
import AcceptCampaign from "../../Services/apicalls/EngageCampaign";

import { ReactComponent as SuccessIllus } from "../../Assets/svgs/success.svg";
import { ReactComponent as Contract } from "../../Assets/svgs/collab.svg";
import { ReactComponent as Youtube } from "../../Assets/svgs/youtube.svg";
import { ReactComponent as Instagram } from "../../Assets/svgs/instagram.svg";

import CampaignCreatorStatusEnum from "../../Enums/CampaignCreatorStatus";
import { Watch } from "react-loader-spinner";
import SocialPlatformsEnum from "../../Enums/SocialPlatformEnum";
import Interval from "../../Utils/intervals";
import GetInstagramTokenFromCode from "../../Services/Instagram/GetInstagramTokenFromCode";
import ChannelConnectType from "../../Enums/ChannelConnectTypeEnum";
import AddCreatorChannel, {
    AddInstaWebCreatorChannel,
} from "../../Services/apicalls/AddCreatorChannel";
import CheckUserFromToken from "../../Services/Instagram/CheckUserFromToken";
import getProfessionalInstagramAccount from "../../Utils/getProfessionalInstagramAccount";
import CampaignTypeEnum from "../../Enums/CampaignTypeEnum";

const CurrentStepEnum = {
    MobileOtp: 0,
    Channel: 1,
    Bid: 2,
    Reward: 3,
    0: "MobileOtp",
    1: "Channel",
    2: "Bid",
    3: "Reward"
};

const LoginStateEnum = {
    Enter: 0,
    OTP: 1,
    Success: 2,
    0: "Enter",
    1: "OTP",
    2: "Success",
};

function CampShareEngageCard({
    platformType,
    campaignId,
    togglePopup,
    setTogglePopup,
    setIsLoading,
    setCampaignNotFound,
    campaignType
}) {
    const [creatorDetails, setCreatorDetails] = useState({
        mobile: "",
        channel: "",
        bid: "",
        otp: "",
        creatorData: "",
    });

    const [CurrentStep, setCurrentStep] = useState(CurrentStepEnum.MobileOtp);
    const [ShowOTPInput, setShowOTPInput] = useState(false);
    const [CreatorConnectedChannels, setCreatorConnectedChannels] = useState([]);
    const [SelectedChannel, setSelectedChannel] = useState(null);
    const [ShowDetailsCard, setShowDetailsCard] = useState(false);
    const [BlockAllCTA, setBlockAllCTA] = useState(false);
    const [LoginState, setLoginState] = useState(LoginStateEnum.Enter);
    const [ShowAddChannel, setShowAddChannel] = useState(false);
    const [ShowMultiInsta, setShowMultiInsta] = useState(false);
    const [MultiAccountData, setMultiAccountData] = useState(null);
    const [SelectedMultiAccount, setSelectedMultiAccount] = useState(null);
    const [AllStepsDone, setAllStepsDone] = useState(false);

    const [manualConnToggle, setManualConnToggle] = useState(false);

    const dispatch = useDispatch();

    const handleCreatorDetailsChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setCreatorDetails((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    useEffect(() => {
        //useeffect
        const token = localStorage.getItem("Authorization");
        if (token) {
            let Campaign_Id = window.location.pathname.split("/engage/")[1];

            axios
                .get(`${constants.API_URL}/Bridge/${Campaign_Id}/getDetailedCampaign`)
                .then((data) => {
                    const CampaignCreator = data?.data?.data?.campaignCreators.length
                        ? data?.data?.data?.campaignCreators[0]
                        : null;
                    const campaignCreatorAvailable = Boolean(CampaignCreator);
                    console.log("CampaignCreatorCampaignCreator", CampaignCreator);

                    if (!campaignCreatorAvailable) {
                        return;
                    }
                    const campaignCreatorStatus = CampaignCreator?.status;
                    if (
                        campaignCreatorStatus === CampaignCreatorStatusEnum.Undefined ||
                        campaignCreatorStatus === CampaignCreatorStatusEnum.invited
                    ) {
                        return;
                    }
                    if (!Boolean(CampaignCreator.bids?.length)) {
                        setCurrentStep(CurrentStepEnum.Bid);
                    } else {
                        setCreatorDetails((prev) => ({
                            ...prev,
                            bid: CampaignCreator.bids[0].creatorQuote,
                        }));
                        setCurrentStep(CurrentStepEnum.Bid);
                        setSelectedChannel(CampaignCreator.selectedChannels[0]);
                        setAllStepsDone(true);
                        return setTogglePopup(true);
                    }

                    if (!Boolean(CampaignCreator.selectedChannels.length)) {
                        setCurrentStep(CurrentStepEnum.Channel);
                    } else {
                        setSelectedChannel(CampaignCreator.selectedChannels[0]);

                        if (campaignType !== CampaignTypeEnum.Paid) {
                            setCurrentStep(CurrentStepEnum.Reward)
                           return setTogglePopup(true);
                        }
                    }

                    return setTogglePopup(true);
                })
                .catch((err) => {
                    console.log(err);
                    setCampaignNotFound(true);
                });
            getCreatorAndSetChannelData();
        }

        return () => { };
    }, []);

    const getCreatorAndSetChannelData = () => {
        axios.get(constants.API_URL + "/Creator").then((data) => {
            console.log("creator data", data);
            setCreatorDetails((prev) => {
                return {
                    ...prev,
                    mobile: data.data.phoneNumber.slice(2, 12),
                };
            });
            setShowAddChannel(false);
            setShowMultiInsta(false)
            setMultiAccountData([])
            setCurrentStep(CurrentStepEnum.Channel);

            const AllChannels = data.data.channels;
            const PlatformChannels = AllChannels.filter(
                (item) => item.platformType === platformType
            );
            if (!Boolean(PlatformChannels.length)) {
                setShowAddChannel(true);
            }
            setCreatorConnectedChannels(PlatformChannels);
        });
    };

    useEffect(() => { }, [SelectedChannel]);

    const SendOTP = () => {
        const MobileNumber = creatorDetails.mobile;
        if (MobileNumber.length === 10) {
            setBlockAllCTA(true);
            let formData = new FormData();
            formData.append("phoneNumber", `91${MobileNumber}`);
            formData.append("isRetry", false);

            OTPLimiter()
                .then((data) => {
                    if (!data.status) {
                        return;
                    }

                    return axios.post(
                        constants.API_URL + "/User/RequestPhoneOtp",
                        formData,
                        {
                            headers: { "content-type": "multipart/form-data" },
                        }
                    );
                })
                .then((data) => {
                    console.log("datatata", data);
                    if (!Boolean(data.data.status)) {
                        return new Promise((resolve, reject) => reject(data.data.message));
                    }
                    setLoginState(LoginStateEnum.OTP);
                    return setShowOTPInput(true);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err.message);
                })
                .finally(() => setBlockAllCTA(false));
        }
        if (MobileNumber.length !== 10) {
            toast.error("Please add a valid number.");
        }
    };

    const submitOtp = () => {
        setIsLoading(true);
        setBlockAllCTA(true);
        submitMobileOtp({
            otp: creatorDetails.otp,
            mobileNumber: creatorDetails.mobile,
        })
            .then((data) => {
                localStorage.setItem("Authorization", `Bearer ${data.data.token}`);
                tokenCheck();
                setLoginState(LoginStateEnum.Success);
                // let Campaign_Id = window.location.pathname.split("/engage/")[1];

                // return axios.get(`${constants.API_URL}/Bridge/${Campaign_Id}/getDetailedCampaign`)
                return axios.get(constants.API_URL + "/Creator");
            })
            .then((data) => {
                setCurrentStep(CurrentStepEnum.Channel);
                console.log("dafadadadaad", data);
                const AllChannels = data.data.channels;
                setCreatorDetails((prev) => {
                    return {
                        ...prev,
                        creatorData: data.data,
                    };
                });
                const PlatformChannels = AllChannels.filter(
                    (item) => item.platformType === platformType
                );
                return setCreatorConnectedChannels(PlatformChannels);
            })
            .then(() => {
                let Campaign_Id = window.location.pathname.split("/engage/")[1];

                return axios.get(
                    `${constants.API_URL}/Bridge/${Campaign_Id}/getDetailedCampaign`
                );
            })
            .then((data) => {
                const CampaignCreator = data?.data?.data?.campaignCreators.length
                    ? data?.data?.data?.campaignCreators[0]
                    : null;
                const campaignCreatorAvailable = Boolean(CampaignCreator);
                console.log("CampaignCreatorCampaignCreator", CampaignCreator);

                if (!campaignCreatorAvailable) {
                    return;
                }
                const campaignCreatorStatus = CampaignCreator?.status;
                if (
                    campaignCreatorStatus === CampaignCreatorStatusEnum.Undefined ||
                    campaignCreatorStatus === CampaignCreatorStatusEnum.invited
                ) {
                    return;
                }
                if (!Boolean(CampaignCreator.bids?.length)) {
                    setCurrentStep(CurrentStepEnum.Bid);
                } else {
                    setCreatorDetails((prev) => ({
                        ...prev,
                        bid: CampaignCreator.bids[0].creatorQuote,
                    }));
                    setCurrentStep(CurrentStepEnum.Bid);
                    setSelectedChannel(CampaignCreator.selectedChannels[0]);
                    setAllStepsDone(true);
                    return setTogglePopup(true);
                }

                if (!Boolean(CampaignCreator.selectedChannels.length)) {
                    setCurrentStep(CurrentStepEnum.Channel);
                } else {
                    setSelectedChannel(CampaignCreator.selectedChannels[0]);
                }

                return setTogglePopup(true);
            })

            .catch((err) => {
                // setShowLoader(false);
                toast.error(err.getErrorMessageToDisplay());
            })
            .finally(() => {
                setIsLoading(false);
                setBlockAllCTA(false);
            });
    };

    const tokenCheck = () => {
        ApiService.setHeader();
    };

    function getInstagramProfileCode(str) {
        const instagramPattern =
            /(?:https?:\/\/)?(?:www.)?(instagram)(?:.com\/)?([@a-zA-Z0-9-_.]+)/gim; //i would handle all case sensitive urls

        const match = !str?.includes("instagram/p/")
            ? instagramPattern.exec(str)
            : null;

        if (match !== null && match?.length > 0) {
            let code = match
                ?.map((entry) => {
                    if (
                        !entry?.includes("http://") &&
                        !entry?.includes("https://") &&
                        !entry?.includes("www.") &&
                        !entry?.includes("instagram.com") &&
                        !entry?.includes("instagram") &&
                        !entry?.includes("com") &&
                        entry?.length > 0
                    ) {
                        return entry;
                    }
                })
                ?.filter((x) => x !== undefined);

            return code[0];
        }
    }

    const AddCreatorChannelManually = () => {
        let ChannelId = 0;
        setIsLoading(true);
        setBlockAllCTA(true);

        let temp = {
            platform: platformType,
            channelHandel:
                creatorDetails.channel?.includes("instagram") ||
                    creatorDetails.channel?.includes("https")
                    ? getInstagramProfileCode(creatorDetails?.channel)
                    : creatorDetails?.channel?.includes("@")
                        ? creatorDetails?.channel?.replace("@", "")
                        : creatorDetails?.channel,
        };

        if (temp?.platform && temp?.channelHandel) {
            AddInstagramManually(temp)
                .then((data) => {
                    console.log("add channel manually", data);
                    if (!Boolean(data.status)) {
                        return new Promise((resolve, reject) => reject(data.message));
                    }

                    //change code here

                    if (!data.status) {
                        return new Promise((resolve, reject) => reject(""));
                    }
                    return getCreatorAndSetChannelData();
                    // ChannelId = parseInt(data.message.split("id ")[1]);
                    // return axios.get(constants.API_URL + "/Creator");
                })
                .catch((error) => console.log("Instagram Token error", error))
                .finally(() => {
                    setBlockAllCTA(false);
                    setIsLoading(false);
                });
        }
    };

    const Engage = () => {
        if (!Boolean(SelectedChannel)) {
            return;
        }
        console.log("channelllll", SelectedChannel);
        setIsLoading(true);
        setBlockAllCTA(true);

        AcceptCampaign(campaignId, creatorDetails.id, [SelectedChannel.id], "")
            .then((data) => {
                setCreatorDetails((prev) => {
                    return {
                        ...prev,
                        channel: SelectedChannel.socialUsername,
                    };
                });
                console.log("campaignData", data);
                if (!Boolean(data.data.status)) {
                    return new Promise((res, reject) => reject(data.message));
                }

                if (campaignType !== CampaignTypeEnum.Paid) {
                    setCurrentStep(CurrentStepEnum.Reward)
                   return setTogglePopup(true);
                }

                setCurrentStep(CurrentStepEnum.Bid);
            })
            .finally(() => {
                setIsLoading(false);
                setBlockAllCTA(false);
            });
    };

    const sendBid = () => {
        if (!Boolean(creatorDetails.bid)) {
            return;
        }
        let token = localStorage.getItem("Authorization");
        setIsLoading(true);
        setBlockAllCTA(true);

        let config = {
            headers: {
                Authorization: token,
            },
        };
        axios
            .get(
                `${constants.API_URL}/Bridge/${campaignId}/getDetailedCampaign`,
                config
            )
            .then((data) => {
                console.log("camp data", data);
                let requestBody = {
                    campaignId,
                    creatorId: data.data.data.campaignCreators[0].id,
                    quote: creatorDetails.bid,
                };

                let token = localStorage.getItem("Authorization");

                let config = {
                    headers: {
                        Authorization: token,
                    },
                };
                return axios.post(
                    `${constants.API_URL}/Bridge/${campaignId}/creatorQuote`,
                    requestBody,
                    config
                );
            })
            .then((data) => {
                console.log("data", data);

                if (data?.data?.status === true) {
                    setTogglePopup(true);
                } else {
                    toast.error("Please try again!");
                }
            })
            .finally(() => {
                setIsLoading(false);
                setBlockAllCTA(false);
            });
    };

    const InstagramChannelConnectFlow = () => {
        let access_token = null;
        setBlockAllCTA(true);
        InstagramChannelCode()
            .then((code) => GetInstagramTokenFromCode(code))
            .then((token) => {
                access_token = token;
                return CheckUserFromToken(token);
            })
            .then((data) => getProfessionalInstagramAccount(data?.accounts))
            .then((instagramData) => {
                if (!Boolean(instagramData.length)) {
                    return new Promise((resolve, reject) => reject("no account found"));
                }
                if (instagramData.length > 1) {
                    // if there are more than one account find. return channel data for dropdown
                    let instaAcc = instagramData.filter((item) => Boolean(item));
                    return {
                        type: "showMultiAccount",
                        data: instaAcc,
                        accessToken: access_token,
                    };
                }

                const requestBody = {
                    socialId: instagramData[0].socialId,
                    accessToken: access_token,
                    socialUsername: instagramData[0].socialUsername,
                    platformType: SocialPlatformsEnum.Instagram,
                };

                return AddCreatorChannel({
                    channelInfo: requestBody,
                    accId: instagramData[0].instagramAccountId,
                    page_access_token: instagramData[0].page_access_token,
                    connectType: ChannelConnectType.InstagramProfessional,
                });
            })
            .then((data) => {
                if (data?.type === "showMultiAccount") {
                    setMultiAccountData({
                        ...data,
                    });
                    return setShowMultiInsta(true);
                }

                if (!data.status) {
                    return new Promise((resolve, reject) => reject(""));
                }
                return getCreatorAndSetChannelData();
            })
            .catch((error) => console.log("Instagram Token error", error))
            .finally(() => setBlockAllCTA(false));
    };

    const InstagramChannelCode = () => {
        return new Promise((resolve, reject) => {
            let a = window.open(
                constants.instagram.accountConnectURI,
                "FacebookInstagramConnect",
                "popup,width=1000,height=700,location=no,left=-1000,top=-1000"
            );

            const URLWatcher = Interval.make(() => {
                try {
                    console.log("locatioooon", a.location.href);
                    let code = "";
                    let currentLocation = a.location.href;

                    if (currentLocation.includes("?code=")) {
                        code = currentLocation.split("?code=")[1].replace("#_=_", "");
                        a.close();
                        Interval.clear(URLWatcher);

                        return resolve(code);
                    }
                } catch (Err) {
                    console.log(Err);
                }
            });
        });
    };

    const GetYTAccessToken = () => {
        return new Promise((resolve, reject) => {
            let a = window.open(
                constants.youtube.brandLink,
                "YoutubeConnect",
                "popup,width=1000,height=700,location=no,left=-1000,top=-1000"
            );

            const URL_WATCHER = Interval.make(() => {
                try {
                    console.log("print");
                    let accessTokenURL = a.location.href;
                    console.log("acccccccC", accessTokenURL);
                    let accessTokenSecret = null;

                    if (
                        !accessTokenURL.includes("/signin/oauth/") &&
                        !accessTokenURL.includes("about:blank")
                    ) {
                        console.log("acccesss at", accessTokenURL);
                        if (constants.IS_ENV_DEV) {
                            accessTokenSecret = accessTokenURL
                                .replace("https://localhost:3000/loader?code=", "")
                                .replace(
                                    "&scope=https://www.googleapis.com/auth/youtube.readonly%20https://www.googleapis.com/auth/yt-analytics.readonly",
                                    ""
                                );
                        } else {
                            accessTokenSecret = accessTokenURL
                                .replace(
                                    "https://creator.lookwhostalking.co.in/loader?code=",
                                    ""
                                )
                                .replace(
                                    "&scope=https://www.googleapis.com/auth/youtube.readonly%20https://www.googleapis.com/auth/yt-analytics.readonly",
                                    ""
                                );
                        }
                    }

                    if (accessTokenSecret) {
                        Interval.clear(URL_WATCHER);
                        console.log(accessTokenSecret);

                        //call add creator channel call

                        a.close();
                        a = null;

                        let requestBody = {
                            accessTokenSecret: accessTokenSecret,
                            platformType: SocialPlatformsEnum.Youtube,
                            connectType: ChannelConnectType.YoutubeBrand,
                            IsLongUpdate: false,
                            // creatorId: creatorData?.Id,
                        };

                        console.log(requestBody, "he he boi");
                        return resolve(requestBody);
                    }
                } catch (error) {
                    //do nothing
                    if (a && a.closed) {
                        Interval.clear(URL_WATCHER);
                    }
                }
            }, 1000);
        });
    };

    const YoutubeChannelConnectFlow = () => {
        setBlockAllCTA(true);
        GetYTAccessToken()
            .then((requestBody) => {
                return AddYoutubeCreatorChannel(requestBody);
            })
            .then((data) => {
                if (!data) {
                    return new Promise((resolve, reject) => reject(""));
                }
                return getCreatorAndSetChannelData();
            })
            .then((data) => console.log("YT Channel Add", data))
            .catch((err) => console.log("YT Channel Add error", err))
            .finally(() => setBlockAllCTA(false));
    };

    const AddYoutubeCreatorChannel = (requestBody) => {
        return new Promise((resolve, reject) => {
            AddCreatorChannel({ data: requestBody })
                .then((data) => {
                    return resolve(data);
                })
                .finally(() => { });
        });
    };

    const ChannelConnect = () => {
        switch (platformType) {
            case SocialPlatformsEnum.Instagram:
                return InstagramChannelConnectFlow();
                break;

            case SocialPlatformsEnum.Youtube:
                return YoutubeChannelConnectFlow();

            default:
                break;
        }
    };

    const AddInstaAccount = () => {
        console.log("channel data MultiAccountData", MultiAccountData);
        console.log("channel data SelectedMultiAccount", SelectedMultiAccount);

        const requestBody = {
            socialId: SelectedMultiAccount.socialId,
            accessToken: MultiAccountData.accessToken,
            socialUsername: SelectedMultiAccount.socialUsername,
            platformType: SocialPlatformsEnum.Instagram,
        };
        setBlockAllCTA(true);
        return AddInstaWebCreatorChannel({
            channelInfo: requestBody,
            accId: SelectedMultiAccount.instagramAccountId,
            page_access_token: SelectedMultiAccount.page_access_token,
            connectType: ChannelConnectType.InstagramProfessional,
        })
            .then((data) => {
                if (!data) {
                    return new Promise((resolve, reject) => reject(""));
                }
                return getCreatorAndSetChannelData();
            })
            .catch((error) => console.log("Instagram Token error", error))
            .finally(() => setBlockAllCTA(false));
    };

    return (
        <div className="flex flex-col items-center justify-center py-6 lg:py-0 w-full xl:fixed xl:top-1/1 xl:right-0 xl:w-[35%]">
            {" "}
            <div
                className="flex flex-col w-full xl:w-[80%] h-fit py-8 rounded-lg transition-all 500ms ease bg-white px-6"
                id="cta"
                style={{
                    boxShadow:
                        "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                }}
            >
                {ShowDetailsCard ? (
                    <>
                        <span className="font-medium text-xl text-center text-[#222] ">
                            Onboarding
                        </span>
                        <div className="mb-6"></div>

                        {console.log("current step", CurrentStepEnum[CurrentStep])}

                        {/* login */}
                        <div className="h-fit w-full">
                            <div className="w-full border-[#999] border-[1px] rounded-lg py-0 min-h-[50px] max-h-[50px] relative flex flex-row">
                                <div className="font-medium text-sm ml-2 absolute top-[-11px] bg-white px-2 text-[#666]">
                                    Contact Number
                                </div>

                                <input
                                    name="mobile"
                                    type="number"
                                    placeholder="9876543210"
                                    value={creatorDetails.mobile}
                                    onChange={handleCreatorDetailsChange}
                                    required
                                    maxLength={10}
                                    className="w-full h-[48px] ml-5 font-medium focus-visible:outline-none"
                                    style={{ letterSpacing: "6px" }}
                                    disabled={
                                        BlockAllCTA ||
                                        LoginState !== LoginStateEnum.Enter ||
                                        CurrentStep !== CurrentStepEnum.MobileOtp
                                    }
                                />
                                {LoginState === LoginStateEnum.Enter &&
                                    CurrentStep === CurrentStepEnum.MobileOtp ? (
                                    <div
                                        onClick={SendOTP}
                                        className={`border-[0.5px] flex flex-row justify-center border-gray-400 items-center px-2.5 whitespace-nowrap rounded-lg text-sm ${creatorDetails.mobile.length === 10
                                                ? "bg-[#f15847] text-white"
                                                : "bg-gray-300 text-white"
                                            } ${creatorDetails.mobile.length === 10
                                                ? "hover:border-[#f15847] hover:text-[#f15847] hover:bg-white cursor-pointer"
                                                : ""
                                            } ${BlockAllCTA
                                                ? "border-[#f15847] text-[#f15847] bg-white cursor-pointer border-[1px]"
                                                : ""
                                            } transition-all 1s ease-linear flex flex-row flex-nowrap`}
                                    >
                                        Send OTP
                                        {BlockAllCTA ? (
                                            <Watch
                                                visible={true}
                                                height="20"
                                                width="20"
                                                color="#f15847"
                                                ariaLabel="watch-loading"
                                                wrapperStyle={{ marginLeft: 10 }}
                                                wrapperClass=""
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : !BlockAllCTA &&
                                    CurrentStep === CurrentStepEnum.MobileOtp ? (
                                    <div
                                        onClick={() => {
                                            setCreatorDetails((prev) => {
                                                return {
                                                    ...prev,
                                                    mobile: "",
                                                };
                                            });
                                        }}
                                        className={`border bg-gray-300 flex flex-row justify-center items-center px-7 cursor-pointer text-sm whitespace-nowrap rounded-md transition-all 1s ease-linear flex-nowrap hover:border-[#f15847] hover:text-white hover:bg-[#f15847]`}
                                    >
                                        clear
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>

                            {LoginState === LoginStateEnum.OTP ? (
                                <div className="w-full border-[#999] border-[1px] rounded-lg py-0 min-h-[50px] max-h-[50px] relative flex flex-row mt-8">
                                    <div className="font-medium ml-2 absolute top-[-11px] text-sm bg-white px-2 text-[#666]">
                                        Enter OTP
                                    </div>
                                    <input
                                        name="otp"
                                        type="number"
                                        placeholder="9876"
                                        value={creatorDetails.otp}
                                        onChange={handleCreatorDetailsChange}
                                        required
                                        maxLength={4}
                                        className="w-full h-[48px] ml-5 focus-visible:outline-none font-medium"
                                        style={{ letterSpacing: "6px" }}
                                        disabled={BlockAllCTA}
                                    />
                                    <div
                                        onClick={submitOtp}
                                        className={`border-[0.5px] flex flex-row text-sm justify-center items-center px-3 whitespace-nowrap rounded-lg ${creatorDetails.otp.length === 4
                                                ? "bg-[#f15847] text-white"
                                                : !BlockAllCTA
                                                    ? "bg-gray-300 text-white"
                                                    : ""
                                            } ${creatorDetails.otp.length === 4
                                                ? "bg-[#f15847] text-[#fff] cursor-pointer"
                                                : ""
                                            } ${BlockAllCTA
                                                ? "border-[#f15847] text-[#f15847] bg-white cursor-pointer border-[1px]"
                                                : ""
                                            } transition-all 1s ease-linear flex flex-row flex-nowrap`}
                                    >
                                        Submit
                                        {BlockAllCTA ? (
                                            <Watch
                                                visible={true}
                                                height="20"
                                                width="20"
                                                color="#f15847"
                                                ariaLabel="watch-loading"
                                                wrapperStyle={{ marginLeft: 10 }}
                                                wrapperClass=""
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        {/* channel connect */}
                        {CurrentStep !== CurrentStepEnum.MobileOtp ? (
                            <div className="w-full border-[#999] border-[1px] rounded-lg py-0 min-h-[60px] relative flex flex-col mt-10 ">
                                {CurrentStep !== CurrentStepEnum.Bid && CurrentStep !== CurrentStepEnum.Reward ? (
                                    <>
                                        <div className="font-medium ml-2 absolute text-sm top-[-11px] bg-white px-2 text-[#666]">
                                            Select Profile
                                        </div>
                                        {console.log("channeeell", platformType)}
                                        {CreatorConnectedChannels.length >= 1 && !ShowAddChannel ? (
                                            <>
                                                <div className="flex flex-row flex-wrap py-6 px-4 justify-between w-full">
                                                    {CreatorConnectedChannels.length < 4 || true ? (
                                                        <>
                                                            <div
                                                                onClick={() => setShowAddChannel(true)}
                                                                className="font-medium text-sm text-[#f15847] absolute top-1 right-1 hover:underline pr-2 cursor-pointer"
                                                            >
                                                                + Add another channel
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {CreatorConnectedChannels.map((item) => {
                                                        return (
                                                            <div
                                                                className="flex items-center font-semibold mb-2.5 cursor-pointer w-[45%] overflow-scroll hover:ring-blue-400 hover:ring-2"
                                                                onClick={() => {
                                                                    setSelectedChannel(item);
                                                                }}
                                                                style={{
                                                                    borderWidth:
                                                                        SelectedChannel?.id === item.id ? 1 : 0,
                                                                    borderRadius: 100,
                                                                    padding: 5,
                                                                    borderColor:
                                                                        SelectedChannel?.id === item.id
                                                                            ? "#60a5fa"
                                                                            : "#ccc",
                                                                    scrollbarWidth: "none",
                                                                }}
                                                            >
                                                                <img
                                                                    src={item?.thumbnail}
                                                                    alt=""
                                                                    className="h-8 w-8 rounded-full mr-1"
                                                                />
                                                                <span
                                                                    className="text-blue-500 font-normal text-sm max-w-50%"
                                                                    style={{ textOverflow: "ellipsis" }}
                                                                >
                                                                    {item.socialUsername?.includes("@")
                                                                        ? item.socialUsername
                                                                        : "@" + item.socialUsername}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div
                                                    onClick={Engage}
                                                    className={`w-fit mb-5 py-2 border-[0.5px] flex flex-row justify-center items-center px-10 self-center whitespace-nowrap select-none rounded-lg ${SelectedChannel
                                                            ? "bg-[#f15847] text-white"
                                                            : !BlockAllCTA
                                                                ? "bg-slate-300 text-white"
                                                                : ""
                                                        } font-light ${SelectedChannel
                                                            ? "hover:border-[#f15847] hover:text-[#f15847] hover:bg-white cursor-pointer"
                                                            : ""
                                                        }  transition-all 1s ease-linear flex flex-row flex-nowrap`}
                                                >
                                                    Proceed
                                                </div>
                                            </>
                                        ) : platformType === SocialPlatformsEnum.Youtube ? (
                                            <div
                                                onClick={ChannelConnect}
                                                className={`border-[0.5px] h-full m-5 flex flex-col min-h-[60px] justify-center items-center px-2 whitespace-nowrap rounded-lg bg-[#f15847] text-white font-light hover:border-[#f15847] hover:text-[#f15847] hover:bg-white cursor-pointer ${BlockAllCTA
                                                        ? "border-[#f15847] text-[#f15847] bg-white cursor-pointer border-[1px]"
                                                        : ""
                                                    } transition-all 1s ease-linear flex flex-row flex-nowrap`}
                                            >
                                                {BlockAllCTA ? (
                                                    <Watch
                                                        visible={true}
                                                        height="30"
                                                        width="30"
                                                        color="#f15847"
                                                        ariaLabel="watch-loading"
                                                        wrapperStyle={{ marginRight: 10 }}
                                                        wrapperClass=""
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                                Add Youtube Channel
                                            </div>
                                        ) : // add instagram channel flow
                                            ShowMultiInsta ? (
                                                <div className="p-5 font-semibold flex-col flex">
                                                    <span className="w-full">Select Account</span>
                                                    <span className="w-full font-light mt-1">
                                                        We've found {MultiAccountData?.data?.length} instagram
                                                        professional accounts connected to your facebook
                                                        account. Please select the account to connect.
                                                    </span>
                                                    {MultiAccountData &&
                                                        MultiAccountData?.data?.map((data) => {
                                                            if (data) {
                                                                return (
                                                                    <div
                                                                        onClick={() => {
                                                                            setSelectedMultiAccount(data);
                                                                        }}
                                                                        className={`flex flex-col justify-center items-center px-2 border-[0.5px] rounded-md mt-5 select-none hover:border-[#f15847] cursor-pointer ${SelectedMultiAccount?.socialUsername ===
                                                                                data.socialUsername
                                                                                ? "border-[#f15847]"
                                                                                : "border-[#ccc]"
                                                                            }`}
                                                                    >
                                                                        <div
                                                                            className={`py-2 first-letter:text-slate-800 w-full text-left hover:text-[#f15847] hover:font-semibold ${SelectedMultiAccount?.socialUsername ===
                                                                                    data.socialUsername
                                                                                    ? "text-[#f15847] font-semibold"
                                                                                    : "text-[#666] font-light"
                                                                                }`}
                                                                        >
                                                                            @ {data.socialUsername}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return <></>;
                                                            }
                                                        })}
                                                    <div
                                                        onClick={AddInstaAccount}
                                                        className={` mt-5 h-full flex flex-col min-h-[50px] justify-center items-center px-2 whitespace-nowrap rounded-lg font-light hover:border-[#f15847] hover:text-[#f15847] hover:bg-white cursor-pointer ${BlockAllCTA || !Boolean(SelectedMultiAccount)
                                                                ? "border-[#f15847] text-[#f15847] bg-white cursor-pointer border-[1px]"
                                                                : "bg-[#f15847] text-white border-[0.5px]"
                                                            } transition-all 1s ease-linear flex flex-row flex-nowrap`}
                                                    >
                                                        Add Via Official Meta API
                                                        {BlockAllCTA ? (
                                                            <Watch
                                                                visible={true}
                                                                height="30"
                                                                width="30"
                                                                color="#f15847"
                                                                ariaLabel="watch-loading"
                                                                wrapperStyle={{ marginRight: 10 }}
                                                                wrapperClass=""
                                                            />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div
                                                        className="absolute top-1.5 right-2.5 font-medium text-sm text-blue-500 cursor-pointer"
                                                        onClick={() => {
                                                            setShowAddChannel(false);
                                                        }}
                                                    >
                                                        Go back
                                                    </div>
                                                    <div className="flex flex-col items-center justify-center p-5 mt-4">
                                                        <div
                                                            onClick={ChannelConnect}
                                                            className={`items-center border-[0.5px] h-full min-h-[40px] max-h-[40px] justify-center px-3 whitespace-nowrap rounded-lg bg-[#f15847] text-white cursor-pointer  transition-all 1s ease-linear flex flex-row flex-nowrap`}
                                                        >
                                                            Add Via Official Meta API
                                                            {BlockAllCTA ? (
                                                                <Watch
                                                                    visible={true}
                                                                    height="20"
                                                                    width="20"
                                                                    color="#fff"
                                                                    ariaLabel="watch-loading"
                                                                    wrapperStyle={{ marginLeft: 10 }}
                                                                    wrapperClass=""
                                                                />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="w-full flex flex-row justify-center items-center">
                                                        <span className="text-[#666] font-medium">or</span>
                                                    </div>

                                                    {!manualConnToggle && (
                                                        <div className="flex flex-col items-center justify-center p-5 w-full">
                                                            <button
                                                                onClick={() => setManualConnToggle(true)}
                                                                className={`border-[0.5px] h-full flex flex-col min-h-[40px] max-h-[40px] justify-center items-center px-2 whitespace-nowrap rounded-lg bg-[#f15847] text-white font-light hover:border-[#f15847] hover:text-[#f15847] hover:bg-white cursor-pointer  transition-all 1s ease-linear flex-nowrap`}
                                                            >
                                                                Connect Manually
                                                            </button>
                                                        </div>
                                                    )}

                                                    {manualConnToggle && (
                                                        <div className="flex flex-col items-center justify-center p-5 w-full">
                                                            <input
                                                                name="channel"
                                                                placeholder="@lookwhostalking_"
                                                                value={creatorDetails.channel}
                                                                onChange={handleCreatorDetailsChange}
                                                                required
                                                                className="w-[80%] h-[40px] px-4 font-medium focus-visible:outline-none text-sm border-2 pr-1 rounded-md mb-5"
                                                                disabled={BlockAllCTA}
                                                            />
                                                            <button
                                                                onClick={AddCreatorChannelManually}
                                                                disabled={creatorDetails.channel.length === 0}
                                                                className={`border-[0.5px] h-full flex min-h-[40px] max-h-[40px] justify-center items-center px-3 whitespace-nowrap rounded-lg ${Boolean(creatorDetails.channel.length)
                                                                        ? "bg-[#f15847] text-white"
                                                                        : "bg-gray-300 text-white"
                                                                    } font-light ${Boolean(creatorDetails.channel.length)
                                                                        ? "hover:border-[#f15847] hover:text-[#f15847] hover:bg-white cursor-pointer"
                                                                        : ""
                                                                    }  transition-all 1s ease-linear flex flex-row flex-nowrap w-fit self-center`}
                                                            >
                                                                Add Channel
                                                                {BlockAllCTA ? (
                                                                    <Watch
                                                                        visible={true}
                                                                        height="20"
                                                                        width="20"
                                                                        color="#f15847"
                                                                        ariaLabel="watch-loading"
                                                                        wrapperStyle={{ marginLeft: 10 }}
                                                                        wrapperClass=""
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </button>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                    </>
                                ) : (
                                    <>
                                        <div className="font-medium ml-2 absolute top-[-16px] text-sm bg-white px-2 text-[#666]">
                                            Connected Channel
                                        </div>
                                        <div
                                            className="flex items-center font-semibold my-2.5 mx-5 py-0.5 max-w-fit min-w-[80%] md:min-w-[50%] overflow-scroll"
                                            style={{
                                                borderWidth: 2,
                                                borderRadius: 100,
                                                padding: 4,
                                                borderColor: "#60a5fa",
                                                scrollbarWidth: "none",
                                            }}
                                        >
                                            <img
                                                src={SelectedChannel?.thumbnail}
                                                alt=""
                                                className="h-10 w-10 rounded-full mr-1"
                                            />
                                            <span className="text-blue-500 font-normal w-[98%] truncate">
                                                {SelectedChannel.socialUsername?.includes("@")
                                                    ? SelectedChannel.socialUsername
                                                    : "@" + SelectedChannel.socialUsername}
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <></>
                        )}

                        {/* {CurrentStep === CurrentStepEnum.Channel ? (
                                CreatorConnectedChannels.length >= 1 ? (
                                    <>
                                        {console.log("selectedChannel", SelectedChannel)}
                                        <div className="font-semibold text-[#a3a3a3] mb-2 mt-4">
                                            Select Channel
                                        </div>
                                        {CreatorConnectedChannels.map((item) => {
                                            return (
                                                <div
                                                    className="flex items-center font-semibold mb-2.5 py-0.5 cursor-pointer hover:ring-[#f15847] hover:ring-1"
                                                    onClick={() => {
                                                        setSelectedChannel(item);
                                                    }}
                                                    style={{
                                                        borderWidth: SelectedChannel?.id === item.id ? 2 : 2,
                                                        borderRadius: 10,
                                                        padding: 5,
                                                        borderColor:
                                                            SelectedChannel?.id === item.id ? "#f15847" : "#ccc",
                                                    }}
                                                >
                                                    <img
                                                        src={item?.thumbnail}
                                                        alt=""
                                                        className="h-10 w-10 rounded-full mr-2.5 border"
                                                    />
                                                    <text className="text-blue-500 font-normal">
                                                        {item.socialUsername?.includes("@")
                                                            ? item.socialUsername
                                                            : "@" + item.socialUsername}
                                                    </text>
                                                </div>
                                            );
                                        })}

                                        <button
                                            onClick={Engage}
                                            className="animate-fade-up w-fit ml-auto px-2 py-1 border-2 rounded-lg hover:border-[#f15847] transition-all 500ms ease text-sm"
                                        >
                                            Proceed
                                        </button>
                                    </>
                                ) : (
                                    <div className="animate-fade-up flex items-end h-fit w-full mt-4">
                                        <InputLabel
                                            label="Social Channel"
                                            name="channel"
                                            placeholder="@user_name"
                                            value={creatorDetails?.channel}
                                            onChange={handleCreatorDetailsChange}
                                            required={true}
                                        />
                                        <button
                                            onClick={AddCreatorChannel}
                                            className="animate-fade-up w-fit ml-auto px-2 py-1 border-2 rounded-lg hover:border-[#f15847] transition-all 500ms ease text-sm"
                                        >
                                            Proceed
                                        </button>
                                    </div>
                                )
                            ) : creatorDetails.channel ? (
                                <div className="flex items-center w-full">
                                    <span className="font-semibold text-[#a3a3a3]">Channel : </span>
                                    <text className="font-semibold text-blue-500 ml-4">
                                        {creatorDetails?.channel?.includes("@")
                                            ? creatorDetails?.channel
                                            : "@" + creatorDetails?.channel}
                                    </text>
                                </div>
                            ) : (
                                ""
                            )} */}

                        {/* bidding */}
                        {CurrentStep === CurrentStepEnum.Bid && campaignType === CampaignTypeEnum.Paid
                        ? (
                            <div className="h-fit w-full mt-10">
                                <div className="w-full border-[#999] border-[1px] rounded-lg py-0 min-h-[50px] max-h-[50px] relative flex flex-row">
                                    <div className="font-medium ml-2 absolute top-[-16px] text-sm bg-white px-2 text-[#666]">
                                        Your Bid
                                    </div>

                                    <input
                                        name="bid"
                                        type="number"
                                        placeholder="Enter your Bid"
                                        value={creatorDetails?.bid}
                                        onChange={handleCreatorDetailsChange}
                                        required={true}
                                        className="w-full h-[48px] ml-5 font-medium focus-visible:outline-none"
                                        disabled={BlockAllCTA || AllStepsDone}
                                    />
                                    {!AllStepsDone ? (
                                        <div
                                            onClick={sendBid}
                                            className={`border-[0.5px] flex flex-row justify-center items-center px-3 text-sm whitespace-nowrap rounded-lg ${creatorDetails.bid.length
                                                    ? "bg-[#f15847] text-white"
                                                    : "bg-slate-300 text-white"
                                                } ${creatorDetails.bid.length
                                                    ? "hover:border-[#f15847] hover:text-[#f15847] hover:bg-white cursor-pointer"
                                                    : ""
                                                } transition-all 1s ease-linear flex flex-row flex-nowrap`}
                                        >
                                            Send Bid
                                            {BlockAllCTA ? (
                                                <Watch
                                                    visible={true}
                                                    height="20"
                                                    width="20"
                                                    color="#f15847"
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{ marginLeft: 10 }}
                                                    wrapperClass=""
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {AllStepsDone ? (
                            <>
                                <span className="text-center mt-10 mb-2">
                                    We've successfully received your collaboration request <br />{" "}
                                    Please proceed with the LWT App for further steps &
                                    notifications.{" "}
                                </span>
                                <button
                                    onClick={() => {
                                        let campaignId =
                                            window.location.pathname.split("/engage/")[1];
                                            window.open(
                                                `lookwhostalkingapp://campshare=${campaignId}`,
                                                "_self"
                                            );
                                        window.open(
                                            `https://creator.lookwhostalking.co.in/dl?campshare=${campaignId}`,
                                            "_self"
                                        );
                                    }}
                                    className="px-4 py-2 text-sm mt-2 rounded bg-[#f15847] text-white mb-2 w-fit self-center"
                                >
                                    Proceed
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <div className="w-full flex flex-col items-center justify-center overflow-hidden">
                        <Contract className="h-[22vh] mb-5" />
                        <span className="text-center mb-5">
                            Be a part of this campaign. Apply now to send your collaboration
                            request.
                        </span>
                        <button
                            onClick={() => setShowDetailsCard(true)}
                            className="px-10 py-3 border-2 border-[#FFF] text-white hover:text-[#f15847] bg-[#f15847] rounded-lg hover:border-[#f15847] hover:bg-white transition-all 500ms ease text-lg "
                        >
                            Apply Now
                        </button>
                    </div>
                )}
            </div>
            {!ShowDetailsCard ? (
                <button
                    onClick={() => {
                        document
                            .getElementById("cta")
                            .scrollIntoView({ behavior: "smooth" });
                        setShowDetailsCard(true);
                    }}
                    className="flex lg:hidden fixed bottom-0 h-20 bg-[#fff] w-full -z-50 flex-row justify-center items-center select-none border-[#f15847] border-2"
                    style={{ zIndex: 999999999999999 }}
                >
                    <span
                        className="font-light text-[#f15847] text-lg"
                        style={{ letterSpacing: 1 }}
                    >
                        Apply Now
                    </span>
                </button>
            ) : (
                <></>
            )}
            {togglePopup && (
                <div>
                    <div
                        className="flex flex-col items-center justify-start fixed top-0 right-0 bottom-0 left-0 m-auto 
        w-[90%] xl:w-[60%] px-4 py-4 bg-white rounded-xl drop-shadow-l min-h-[50vh] max-h-fit"
                        style={{
                            zIndex: 999994,
                        }}
                    >
                        <div
                            className="py-2 px-5 absolute right-0 text-black text-[40px] top-0 cursor-pointer"
                            onClick={() => setTogglePopup(false)}
                        >
                            ×
                        </div>

                        <span className="flex w-full items-center justify-center font-medium mb-4 text-2xl">
                            Collaboration
                        </span>
                        <div className="flex w-full items-center justify-center py-4 mt-auto mb-2">
                            <SuccessIllus className="h-[20vh]" />
                        </div>
                        <span className="text-center mt-auto mb-2 text-lg">
                            We've successfully received your collaboration request <br />{" "}
                            Please proceed with the LWT App for further steps & notifications.{" "}
                        </span>
                        <button
                            onClick={() => {
                                let campaignId = window.location.pathname.split("/engage/")[1];
                                window.open(
                                    `lookwhostalkingapp://campshare=${campaignId}`,
                                    "_self"
                                );
                                window.open(
                                    `https://creator.lookwhostalking.co.in/dl?campshare=${campaignId}`,
                                    "_self"
                                );
                            }}
                            className="px-2.5 py-1 text-sm mt-2 rounded bg-[#f15847] text-white mb-2"
                        >
                            Proceed
                        </button>
                    </div>
                    <div
                        className="fixed h-screen bg-black/30 w-full top-0 left-0 backdrop-blur-sm"
                        style={{
                            zIndex: 999993,
                            pointerEvents: "all",
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
}

export default CampShareEngageCard;

const InputLabel = ({
    name,
    type,
    onChange,
    value,
    label,
    width,
    required,
    disabled,
    pattern,
    placeholder,
    maxLength,
}) => {
    return (
        <div
            className={`md:${width || "w-1/2"
                } mb-2 md:mb-0 px-3 flex flex-col w-full `}
        >
            <div className="flex">
                <Label name={name} label={label} />
            </div>

            <Input
                name={name}
                id={name}
                type={type || "text"}
                value={value}
                onChange={onChange}
                required={required}
                disabled={disabled}
                pattern={pattern}
                placeholder={placeholder}
                maxLength
            />
        </div>
    );
};

const Input = ({
    name,
    type,
    onChange,
    value,
    required,
    disabled,
    pattern,
    placeholder,
    maxLength,
}) => {
    return (
        <input
            style={{
                borderColor: "#ccc",
            }}
            maxLength={maxLength || undefined}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            className="flex-1 text-sm appearance-none border border-secondary-400 rounded-lg w-full py-2 px-4 text-secondary-700 leading-tight focus:outline-none "
            id={name}
            type={type || "text"}
            value={value}
            required={required}
            disabled={disabled}
            pattern={pattern}
            autoComplete="off"
        />
    );
};

const Label = ({ label, name, emptyFields, past_today }) => {
    return (
        <label
            className="flex items-center text-xs mb-2 md:mb-1 pr-1 whitespace-nowrap text-[#3d3d3d] font-semibold"
            htmlFor={name}
            style={{
                color: past_today ? "#f15847" : "#3d3d3e",
            }}
        >
            {label}{" "}
        </label>
    );
};
