import React, { useEffect } from 'react'
import { ReactComponent as Close } from "../../Assets/svgs/close.svg";
import { PostType } from '../../Enums/Post&ContentEnum';

const MoodboardPreviewModal = ({ isVisible, abortModalFunction, mediaData, fileType, isDownloadable }) => {
    useEffect(() => {

    }, [isVisible])
    if (!isVisible) {
        return <></>
    }
    return (
        <div>
            <div
                className="flex flex-col items-center justify-start fixed top-0 right-0 bottom-0 left-0 m-auto w-[90vw] h-[90vh] px-4 py-4 bg-white rounded-xl"
                style={{
                    zIndex: 999999,
                }}
            >
                <div className="flex w-full justify-end mb-2">
                    <Close
                        className="h-6 w-6 -mt-2 -mr-2 cursor-pointer"
                        style={{
                            pointerEvents: "all",
                        }}
                        onClick={() => abortModalFunction(false)}
                    />
                </div>
                <div className="flex w-full justify-center text-gray-600 font-semibold">
                    PREVIEW
                </div>
                <div className="flex flex-col h-[95%] w-full justify-center items-center">
                    {fileType === PostType.Image ? (
                        <img
                            src={mediaData}
                            alt=""
                            className="rounded-lg"
                            style={{ maxHeight: "80%", minHeight: "60%" }}
                        />
                    ) : (
                        <video
                            controls
                            autoPlay
                            controlsList="nodownload"
                            height="82%"
                            src={mediaData}
                            type="video/mp4"
                            style={{ height: "82%" }}
                            className="rounded-xl"
                        />
                    )}
                    {
                        isDownloadable ?

                            <div className="flex w-full justify-center mt-[1%]">
                                <a
                                    href={mediaData}
                                    target="_blank"
                                    className="outline-none w-max border-none px-2 py-1 text-xs text-white rounded-xl mr-2"
                                    style={{
                                        backgroundColor: "#f15847",
                                    }}
                                >
                                    download
                                </a>
                            </div>
                            :
                            <></>

                    }

                </div>
            </div>
            <div
                className="fixed h-screen bg-black/30 w-full top-0 left-0 backdrop-blur-sm"
                style={{
                    zIndex: 999989,
                    pointerEvents: "all",
                }}
                onClick={() => abortModalFunction(false)}
            ></div>
        </div>
    );
};

export default MoodboardPreviewModal;