import React, { useEffect, useState } from "react";

import { ReactComponent as Instagram } from "../Assets/svgs/instagram.svg";
import { ReactComponent as Youtube } from "../Assets/svgs/youtube.svg";

import { ReactComponent as AddPost } from "../Assets/svgs/add_post.svg";
import LoaderIcon from "../Assets/gifs/lwtLoader.gif";
import { ReactComponent as Carousel } from "../Assets/svgs/carousel.svg";
import { ReactComponent as Story } from "../Assets/svgs/story.svg";
import { ReactComponent as Reel } from "../Assets/svgs/reel.svg";
import { ReactComponent as Shorts } from "../Assets/svgs/shorts.svg";

import { ReactComponent as Image } from "../Assets/svgs/image.svg";
import { ReactComponent as PlayIcon } from "../Assets/svgs/play.svg";
import { ReactComponent as FileIcon } from "../Assets/svgs/file.svg";
import { ReactComponent as AudioIcon } from "../Assets/svgs/audio.svg";
import { ReactComponent as TextIcon } from "../Assets/svgs/text.svg";
import { ReactComponent as NotFound } from "../Assets/svgs/notfound.svg";
import { ReactComponent as InfoIcon } from "../Assets/svgs/info.svg";
import { ReactComponent as FooterSvg } from "../Assets/svgs/footerSvg.svg";

import { ReactComponent as Tick } from "../Assets/svgs/tick.svg";
import { ReactComponent as Dont } from "../Assets/svgs/dont.svg";

import AndroidDownload from "../Assets/pngs/Playstore-download.png";
import IOSDownload from "../Assets/pngs/Appstore-download.png";

import DocPlaceholder from "../Assets/svgs/doc_placeholder.png";
import AudioPlaceholder from "../Assets/svgs/audio_placeholder.png";

import HeaderComponent from "../components/header";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import moment from "moment";
import { Tooltip } from "react-tooltip";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import { mdToDraftjs } from "draftjs-md-converter";

import { GENREMETA } from "../Enums/GenerEnum";
import { ContentType, PostType } from "../Enums/Post&ContentEnum";
import CampShareEngageCard from "../components/CampShareEngageCard/CampShareEngageCard";
import axios from "axios";
import constants from "../constants";
import { useNavigate } from "react-router-dom";
import SocialPlatformsEnum from "../Enums/SocialPlatformEnum";
import Overlay from "../components/Overlay/Overlay";
import MoodboardPreviewModal from "../components/Overlay/MoodboardPreviewModal";

const Recruitment = () => {
  let sampleData = {
    id: 0,
    brandId: 0,
    name: "",
    brandName: "",
    product: null,
    banner: "",
    hashtag: "",
    objective: "",
    rewardsInfo: "",
    scopeOfWork: "",
    genres: [],
    trackingStart: "2024-03-15T00:00:00",
    trackingEnd: "2024-05-23T08:47:47.88",
    type: 1,
    typeText1: "default",
    typeText2: "default",
    campaignCreators: [
      {
        id: 2350,
        campaignId: 70,
        creatorId: 38323,
        isInvited: false,
        isCreatorAccepted: true,
        isApproved: false,
        rejectedBy: 2,
        rejectionReason:
          "Thank you for your interest in our campaign. While your profile may not fit our current needs, we hope to include you in future campaigns.",
        paymentProcessed: null,
        createdOn: "2024-03-15T09:11:06.192929",
        updatedOn: "2024-03-15T09:15:27.6811364",
        costAcceptedBy: 0,
        costDeclinedBy: 2,
        contentEnabledTimestamp: null,
        bids: [
          {
            campaignCreatorId: 2350,
            attemptIndex: 1,
            brandQuote: 2,
            creatorQuote: 1,
            brandTimestamp: "2024-03-15T09:14:10.641135",
            creatorTimestamp: "2024-03-15T09:11:23.872597",
          },
        ],
        contentItems: [],
        selectedChannels: [],
        status: 9,
        selectedAvgEngagement: 0.0,
        selectedTotalReach: 0,
        getFinalQuote: 0,
        getCreatorQuote: 1,
        getBrandQuote: 2,
        invoice: {
          identityId: null,
          id: 0,
          nameId: null,
          fileUri: null,
          publicFileName: null,
          fileType: 0,
          postFileType: 0,
          uploadType: 0,
          size: 0,
          description: null,
          isLinked: false,
          uploadedOn: "0001-01-01T00:00:00",
          applicationUser: null,
        },
        agreementFile: {
          identityId: null,
          id: 0,
          nameId: null,
          fileUri: null,
          publicFileName: null,
          fileType: 0,
          postFileType: 0,
          uploadType: 0,
          size: 0,
          description: null,
          isLinked: false,
          uploadedOn: "0001-01-01T00:00:00",
          applicationUser: null,
        },
        invoiceApproved: 0,
        rewardsMessage: null,
        creator: null,
        isBidding: true,
        isContentEnabled: false,
      },
    ],
    attachments: [],
    moodboard: [],
    platforms: [4],
    guidelines: [],
    itineraries: [],
    status: 4,
    isRejected: false,
    fileIds: [],
    isContentApprovalEligible: true,
    isAbandoned: false,
    isApproved: true,
    isPaused: false,
    isLiveEligible: true,
    isPublic: 0,
    isExternal: false,
    hasApprovalProcess: true,
    budget: 0,
    orderId: 0,
    cpeAmount: 0.0,
    deliverables: [],
    agreementFile: null,
    eligibility: null,
    relevantCampaignCreatorStatus: 9,
  };

  const [CampaignData, setCampaignData] = useState(sampleData);
  const [CampaignLoading, setCampaignLoading] = useState(true);
  const [campaignId, setCampaignId] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [CampaignNotFound, setCampaignNotFound] = useState(false);
  const [ShowVideoOverlay, setShowVideoOverlay] = useState(false);
  const [VideoUrl, setVideoUrl] = useState('');
  const [PostOverlayType, setPostOverlayType] = useState(PostType.Image)

  const navigate = useNavigate();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    let campaignId = window.location.pathname.split("/engage/")[1];

    if (isNaN(campaignId)) {
      setCampaignNotFound(true);
    }
    console.log("campaignId ", campaignId);
    setCampaignId(campaignId);

    const formData = new FormData();
    formData.append("campId", campaignId);
    setCampaignLoading(true);
    axios
      .post(`${constants.API_URL}/Bridge/GetCampaignInfoForAnon`, formData)
      .then((data) => {
        console.log("This is campaignData", data);
        if (!Boolean(data.data.status)) {
          setCampaignNotFound(true);
        }

        const CampaignData = data.data.data;
        setCampaignData(CampaignData);
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(mdToDraftjs(CampaignData?.scopeOfWork))
          )
        );
      })
      .catch((err) => {
        setCampaignNotFound(true);
      })
      .finally(setCampaignLoading.bind(this, false));
  }, []);

  const [togglePopup, setTogglePopup] = useState(false);

  console.log(CampaignData, "sample");
  return (
    <div className="flex flex-col items-start justify-start h-full w-full bg-[#f8f8f8]">
      {" "}
      <HeaderComponent noUserOptions={true} bgColor={"#f8f8f8"} />
      {!CampaignNotFound ? (
        <div className="flex items-center md:text-left text-center text-5xl font-medium 2xl:px-10 xl:px-8 px-4 mt-0 w-full justify-center">
          Campaign Collaboration
        </div>
      ) : (
        <></>
      )}
      {isLoading && (
        <div
          className="flex flex-row justify-center items-center"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "#00000070",
            top: 0,
            left: 0,
            zIndex: 9999999999,
          }}
        >
          <div
            style={{
              width: 120,
              height: 120,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
              borderRadius: 300,
            }}
          >
            <img className="h-8" src={LoaderIcon} alt="Loading" />
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row w-full items-center justify-center px-4 md:px-12 xl:px-16 mac:px-20 3xl:px-24 py-16">
        {!CampaignNotFound ? (
          <div className="flex xl:flex-row flex-col items-start w-full h-full">
            <div
              className="flex flex-col xl:w-[65%] w-full items-center h-full select-none"
              style={{
                padding: "2% 3%",
                background: "#fff",
                boxShadow:
                  "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                borderRadius: 10,
                userSelect: "none",
              }}
            >
              <span className="font-medium text-xl text-center text-[#222] ">
                Campaign Details
              </span>{" "}
              <div className="flex flex-col justify-start w-full h-full mt-4">
                <div className="flex w-full items-end">
                  <div className="flex w-full items-end justify-between">
                    <img
                      src={
                        CampaignData?.banner
                          ? CampaignData?.banner
                          : "/assets/images/no_image_placeholder.png"
                      }
                      alt="Campaign Banner"
                      className="h-[225px] w-[400px] rounded-xl border"
                    />

                    <div className="sm:flex hidden flex-col justify-end w-full h-[225px] relative ml-4">
                      {CampaignData?.type !== 0 && (
                        <span className=" font-medium text-blue-500 text-2xl">
                          {CampaignData?.type === 1 && "Paid Campaign"}
                          {CampaignData?.type === 2 && "Rewards Campaign"}
                          {CampaignData?.type === 3 && "CPE Campaign"}
                        </span>
                      )}
                      <div className="flex items-center mt-4">
                        {CampaignData?.platforms[0] === 4 && (
                          <span className="flex items-center text-sm font-medium">
                            <Instagram className="h-4 mr-1" /> Instagram
                          </span>
                        )}
                        {CampaignData?.platforms[0] === 1 && (
                          <span className="flex items-center text-sm font-medium">
                            <Youtube className="h-4 mr-1" /> Youtube
                          </span>
                        )}
                      </div>

                      <div className="flex items-center w-full mt-4 flex-wrap">
                        {CampaignData?.genres?.length > 0 &&
                          CampaignData?.genres?.map((data, idx) => {
                            return (
                              <GenreLabels
                                genreLabel={GENREMETA[data - 1]?.label}
                                key={idx}
                                value={data}
                              />
                            );
                          })}
                      </div>
                      <div className="flex w-full items-end justify-between">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="w-fit"
                        >
                          <div className="flex flex-col">
                            <span className="flex items-center text-sm">
                              Starts{" "}
                            </span>
                            <span
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                              className="text-[#3366ff] whitespace-nowrap"
                            >
                              {CampaignData?.status === 3 ||
                                CampaignData?.status === 4
                                ? moment(CampaignData?.trackingStart)
                                  .utcOffset("+05:30")
                                  .format("LL")
                                : moment(new Date())
                                  .utcOffset("+05:30")
                                  .format("LL")}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="w-fit"
                        >
                          <div className="flex flex-col mt-4">
                            <span className="text-sm whitespace-nowrap">
                              {CampaignData?.status === 3 ||
                                CampaignData?.status === 4
                                ? "End "
                                : "Ends "}
                              {CampaignData.trackingEnd
                                ? moment(CampaignData.trackingEnd)
                                  .utcOffset("+05:30")
                                  .fromNow()
                                : "-"}
                            </span>
                            <span
                              style={{
                                color: "rgb(51, 102, 255)",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                              className="whitespace-nowrap"
                            >
                              {CampaignData.trackingEnd
                                ? moment(CampaignData.trackingEnd)
                                  .utcOffset("+05:30")
                                  .format("LL")
                                : "-"}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <div className="flex flex-col justify-end h-full mt-4 w-full">
                    <div className="text-4xl font-medium mt-1">
                      {CampaignData?.name
                        ? CampaignData?.name
                        : `"Campaign Name"`}
                    </div>
                    <div className="flex items-center">
                      <div className=" text-[#666] font-medium mt-1">
                        {CampaignData?.brandName
                          ? CampaignData?.brandName
                          : `"Brand Name"`}
                      </div>
                      <span className="border py-2 mx-2"></span>
                      <div className=" text-[#666] font-medium mt-1">
                        {CampaignData?.hashtag
                          ? CampaignData?.hashtag?.includes("#")
                            ? CampaignData?.hashtag
                            : "#" + CampaignData?.hashtag
                          : `"#CampaignHashtag"`}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex sm:hidden flex-col justify-end w-full h-max mt-4">
                  {CampaignData?.type !== 0 && (
                    <span className="font-medium text-blue-500 text-2xl">
                      {CampaignData?.type === 1 && "Paid Campign"}
                      {CampaignData?.type === 2 && "Rewards Campign"}
                      {CampaignData?.type === 3 && "CPE Campign"}
                    </span>
                  )}
                  <div className="flex items-center mt-2">
                    {CampaignData?.platforms[0] === 4 && (
                      <span className="flex items-center text-sm font-medium">
                        <Instagram className="h-4 mr-1" /> Instagram
                      </span>
                    )}
                    {CampaignData?.platforms[0] === 1 && (
                      <span className="flex items-center text-sm font-medium">
                        <Youtube className="h-4 mr-1" /> Youtube
                      </span>
                    )}
                  </div>

                  <div className="flex items-center w-full mt-4 flex-wrap">
                    {CampaignData?.genres?.length > 0 &&
                      CampaignData?.genres?.map((data, idx) => {
                        return (
                          <GenreLabels
                            genreLabel={GENREMETA[data - 1]?.label}
                            key={idx}
                            value={data}
                          />
                        );
                      })}
                  </div>
                  <div className="flex w-full items-end justify-between">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="w-fit"
                    >
                      <div className="flex flex-col">
                        <span className="flex items-center text-sm">
                          Starts{" "}
                        </span>
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                          className="text-[#3366ff] whitespace-nowrap"
                        >
                          {CampaignData?.status === 3 ||
                            CampaignData?.status === 4
                            ? moment(CampaignData?.trackingStart)
                              .utcOffset("+05:30")
                              .format("LL")
                            : moment(new Date())
                              .utcOffset("+05:30")
                              .format("LL")}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="w-fit"
                    >
                      <div className="flex flex-col mt-4">
                        <span className="text-sm whitespace-nowrap">
                          {CampaignData?.status === 3 ||
                            CampaignData?.status === 4
                            ? "End "
                            : "Ends "}
                          {CampaignData.trackingEnd
                            ? moment(CampaignData.trackingEnd)
                              .utcOffset("+05:30")
                              .fromNow()
                            : "-"}
                        </span>
                        <span
                          style={{
                            color: "rgb(51, 102, 255)",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                          className="whitespace-nowrap"
                        >
                          {CampaignData.trackingEnd
                            ? moment(CampaignData.trackingEnd)
                              .utcOffset("+05:30")
                              .format("LL")
                            : "-"}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full h-fit mt-4">
                <div className="font-medium text-lg">Campaign Objective</div>
                <div className="flex mt-2 w-full h-fit">
                  {CampaignData?.objective}
                </div>
              </div>
              <div className="flex flex-col w-full h-fit mt-4">
                <div className="font-medium text-lg">Scope Of Work</div>
                {!togglePopup && (
                  <div className="flex flex-col items-start justify-start w-full h-fit">
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="h-fit w-full"
                      readOnly={true}
                      toolbarHidden={true}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full h-full mt-4">
                <div className="font-medium text-lg">Campaign Deliverables</div>
                <div className="flex flex-col items-start justify-start mt-4 w-full flex-wrap">
                  {CampaignData?.deliverables?.length > 0 ? (
                    CampaignData?.deliverables?.map((data, idx) => {
                      let dates = new Date(data?.scheduledDate);

                      return (
                        <div
                          key={idx}
                          className="transition-all 0.5s ease flex flex-col items-start justify-between w-full h-fit px-4 py-3 mr-4 mb-4 rounded-lg "
                          style={{
                            boxShadow:
                              "rgba(9, 30, 66, 0.25) 0px 0px 0px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                          }}
                        >
                          <div className="flex w-full items-center justify-between text-sm">
                            <div className="flex w-[40%] items-center">
                              {data?.platformType === 4 ? (
                                <Instagram className="h-4 mr-2" />
                              ) : (
                                <Youtube className="h-4 mr-2" />
                              )}
                              <span className="text-sm font-medium pl-2 border-l">
                                Deliverable {data?.name}
                              </span>
                              <span className="border py-2 mx-2"></span>
                              <div className="flex items-center text-sm">
                                <span
                                  className="flex items-center w-max"
                                  style={{
                                    marginRight:
                                      data?.postFileType !== 0 ? "0.5rem" : "",
                                  }}
                                >
                                  {data?.socialContentType === 1 && (
                                    <AddPost className="h-4 w-4 mr-1" />
                                  )}
                                  {data?.socialContentType === 2 && (
                                    <Carousel className="h-4 w-4 mr-1" />
                                  )}
                                  {data?.socialContentType === 3 && (
                                    <Story className="h-4 w-4 mr-1" />
                                  )}
                                  {data?.socialContentType === 8 && (
                                    <Reel className="h-4 w-4 mr-1" />
                                  )}
                                  {data?.socialContentType === 9 && (
                                    <Shorts className="h-4 w-4 mr-1" />
                                  )}
                                  {ContentType[data?.socialContentType]}
                                </span>
                                {data?.postFileType !== 0 && (
                                  <span className="flex items-center pl-2 border-l w-max">
                                    {data?.postFileType === 1 && (
                                      <Image className="h-4 mr-1" />
                                    )}
                                    {data?.postFileType === 2 && (
                                      <PlayIcon className="h-4 mr-1" />
                                    )}
                                    {data?.postFileType === 4 && (
                                      <TextIcon className="h-4 mr-1" />
                                    )}
                                    {PostType[data?.postFileType]}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <Overlay visible={ShowVideoOverlay} close={() => {
                            setShowVideoOverlay(false)
                            setVideoUrl("")
                            setPostOverlayType(PostType.Image)
                          }}>
                            <div style={{maxWidth: '80%', maxHeight: '90%', minWidth: '30%', minHeight: '30%', background: '#fff', padding: 10}}> */}
                            {
                              // PostOverlayType === PostType.Video ?
                              //   <video style={{maxWidth: '80%', minWidth: '30%'}} height='' src={VideoUrl} v controls autoPlay controlsList="nodownload" oncontextmenu="return false;" >
                              //     {/* <source src={VideoUrl} type="video/mp4"/> */}
                              //   </video>
                              //   :
                              //   <img src={VideoUrl} height={'100%'} width={'100%'}/>
                            }
                          {/* </div>
                          </Overlay> */}

                          <MoodboardPreviewModal isVisible={ShowVideoOverlay} abortModalFunction={() => {
                            setShowVideoOverlay(false)
                            setVideoUrl("")
                            setPostOverlayType(PostType.Image)
                          }} mediaData={VideoUrl} fileType={PostOverlayType} />

                          <div className="flex items-center w-full overflow-y-auto mt-4 py-2">
                            {data?.moodboard &&
                              data?.moodboard?.map((item, idx) => {
                                return (
                                  <div
                                    onClick={() => {
                                      // console.log(item)
                                      setShowVideoOverlay(true)
                                      setVideoUrl(item.fileUri)
                                      setPostOverlayType(item.postFileType)
                                      // window.open()
                                    }}
                                    key={idx}
                                    className="flex justify-center items-end rounded-lg border p-2 mr-4 relative h-[150px] min-w-[180px] max-w-[180px] mac:min-w-[200px] mac:max-w-[200px] xl:min-w-[180px] xl:max-w-[180px] xl:h-[120px]"
                                    style={{
                                      backgroundImage: `${item?.postFileType === 1
                                        ? `url(${item?.fileUri})`
                                        : `url(${encodeURI(
                                          item?.fileUri + "_thumb.jpg"
                                        )})`
                                        }`,
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                  >
                                    <span className="absolute bottom-1 right-1 flex items-center px-2 py-0.5 rounded w-max backdrop-blur-sm bg-white/80 text-xs font-medium">
                                      {data?.postFileType === 1 && (
                                        <Image className="h-4 mr-0.5" />
                                      )}
                                      {data?.postFileType === 2 && (
                                        <PlayIcon className="h-4 mr-0.5" />
                                      )}
                                      {PostType[data?.postFileType]}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="flex items-start w-full text-sm my-1 mt-4">
                            <span className="text-[#a3a3a3] font-medium">
                              Caption
                            </span>{" "}
                            <span className="italic ml-1">
                              {data?.caption?.length > 200
                                ? data?.caption?.slice(0, 200) + "..."
                                : data?.caption}
                            </span>
                          </div>

                          <div className="flex items-start w-full text-sm my-1 mt-4">
                            <span className="text-[#a3a3a3] font-medium">
                              Remarks -
                            </span>{" "}
                            <span className="italic ml-1">{data?.remarks}</span>
                          </div>
                          <div className="flex items-start w-full text-sm my-1 mt-4">
                            <span className="text-[#a3a3a3] font-medium">
                              Tags -
                            </span>{" "}
                            <span className="italic ml-1">
                              {data?.tags?.toString()}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className=" flex items-center justify-center text-sm w-[20rem] border rounded-lg h-[6rem] px-2 py-2 italic">
                      You don't have any 'Deliverables' yet.
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col w-full h-full mt-4">
                <div className="font-medium mt-2 text-lg">
                  Campaign Do's - Dont's
                </div>
                <div className="flex flex-col items-start justify-start mt-2 w-full h-fit">
                  <span className="mb-2 text-sm font-medium">Do's</span>
                  {CampaignData?.guidelines &&
                    CampaignData?.guidelines?.map((data, idx) => {
                      if (data?.type === 1) {
                        return (
                          <div
                            key={idx}
                            className="flex rounded-lg items-center text-sm px-2 py-0.5 my-1 w-full"
                            style={{
                              lineBreak: "anywhere",
                            }}
                          >
                            <Tick className="mr-2 h-4 w-4" />

                            <span
                              className="w-[90%]"
                              style={{ lineBreak: "auto" }}
                            >
                              {data?.text}
                            </span>
                          </div>
                        );
                      }
                    })}
                </div>
                <div className="flex flex-col items-start justify-start mt-2 w-full h-fit">
                  <span className="mb-2 text-sm font-medium">Dont's</span>
                  {CampaignData?.guidelines &&
                    CampaignData?.guidelines?.map((data, idx) => {
                      if (data?.type === 2) {
                        return (
                          <div
                            key={idx}
                            className="flex rounded-lg items-center text-sm px-2 py-0.5 my-1 w-full"
                            style={{
                              lineBreak: "anywhere",
                            }}
                          >
                            <Dont className="mr-2 h-3 w-4" />
                            <span
                              className="w-[90%]"
                              style={{ lineBreak: "auto" }}
                            >
                              {data?.text}
                            </span>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="flex flex-col w-full h-full mt-4">
                <div className="font-medium mt-2 text-lg">
                  Campaign Itinerary
                </div>
                <div className="flex flex-col items-start justify-start mt-2 w-full h-fit">
                  {CampaignData?.itineraries &&
                    CampaignData?.itineraries?.map((data, idx) => {
                      let datetime = new Date(data?.markedDateTime + "Z");
                      let time = datetime.toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      });

                      return (
                        <div
                          key={idx}
                          className="flex rounded-lg items-center justify-start text-sm px-2 py-0.5 my-1 w-full"
                        >
                          <span className="min-w-[20%] max-w-fit mr-2 whitespace-nowrap">
                            {datetime?.toString()?.slice(4, 15) + " " + time}
                          </span>
                          <span className="w-[60%] font-medium">
                            {data?.text}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            {/****Engage Details */}
            <CampShareEngageCard
              campaignType={CampaignData.type}
              platformType={CampaignData.platforms[0]}
              campaignId={campaignId}
              togglePopup={togglePopup}
              setTogglePopup={setTogglePopup}
              setIsLoading={setisLoading}
              setCampaignNotFound={setCampaignNotFound}
            />
          </div>
        ) : (
          <div className="overflow-hidden justify-center items-center flex flex-col">
            <div className="w-full md:w-1/2 overflow-hidden flex flex-row ">
              <NotFound className="h-full " />
            </div>
            <span className="font-normal text-[#666] mt-10 ">
              The campaign invitation page you are looking for, does not exist
              or may be private.
            </span>
            <span className="font-normal text-[#666] mt-3 ">
              Try out our LWT app for campaign collaboration and more.
            </span>
            <span
              onClick={() =>
                window.open("https://creator.lookwhostalking.co.in", "_self")
              }
              className="font-medium text-blue-600 mt-10 cursor-pointer"
            >
              Home
            </span>
            <div className="mt-10 mx-auto">
              <button
                className="outline-none border-none align-self-end mr-4"
                onClick={() => {
                  window.open(`market://details?id=com.lookwhostalkingApp`);
                }}
              >
                <img
                  alt="Download LWT from App Store"
                  src={AndroidDownload}
                  height={120}
                  width={200}
                />
              </button>

              <button
                className="outline-none border-none align-self-end"
                onClick={() => {
                  window.open("itms-apps://itunes.apple.com/app/id1579637316");
                }}
              >
                <img
                  alt="Download LWT from Play Store"
                  src={IOSDownload}
                  height={120}
                  width={200}
                />
              </button>
            </div>
          </div>
        )}
      </div>
      <Tooltip
        id="campaign_review_tooltip"
        style={{
          background: "#818589",
          fontSize: "12px",
          padding: "4px 6px",
        }}
      />
      <div
        className="w-full flex flex-row mb-0 mr-0 lg:mb-[-100px] lg:mr-[-100px]"
        style={{ justifyContent: "flex-end" }}
      >
        <FooterSvg className="h-[30vh]" />
      </div>
      <div className="flex w-full items-center justify-center text-gray-500 text-sm mt-6 mb-3">
        <button
          className="mr-2"
          onClick={() =>
            window.open(
              "https://brand.lookwhostalking.co.in/termsCondition",
              "_blank"
            )
          }
        >
          Terms
        </button>
        <button
          className="border-l-2 pl-2"
          onClick={() =>
            window.open(
              "https://brand.lookwhostalking.co.in/privacyPolicy",
              "_blank"
            )
          }
        >
          Privacy Policy
        </button>
      </div>
      <div className="flex flex-row justify-center items-center w-full mb-5">
        <span
          style={{ fontSize: "14px", fontWeight: 400, textAlign: "center" }}
        >
          Copyright © 2024 Look Who's Talking <br /> All rights reserved
        </span>
      </div>
    </div>
  );
};

export default Recruitment;

const GenreLabels = ({ genreLabel, value }) => {
  return (
    <div className="flex items-center px-2 py-0.5 text-sm rounded-lg bg-[#fff] mr-2 border-2 mb-2">
      {genreLabel}
    </div>
  );
};
