import DLActionEnum from "../Enums/DLActionEnums"
import OperatingSystemEnum from "../Enums/OperatingSystemEnum";
import getMobileOperatingSystem from "./getMobileOperatingSystem";

function DLActionHandler (link) {
    console.log("link", link)
    if (!link) {
        return 
    }
    const LinkActionDataArray = link.split("dl?")[1].split("=")

    const action = LinkActionDataArray[0]
    const data = LinkActionDataArray[1]

    const DeviceType = getMobileOperatingSystem()

    switch (action) {
        case DLActionEnum.referrer:
            const RefSourceId = data
            
            window.open(`lookwhostalkingapp://refer/${RefSourceId}`);

            if(DeviceType === OperatingSystemEnum.android) {
                return window.open(`market://details?id=com.lookwhostalkingApp&referrer=${RefSourceId}`)
            }
            
            if (DeviceType === OperatingSystemEnum.ios) {
                return window.open("itms-apps://itunes.apple.com/app/id1579637316")
            }
            break;

        case DLActionEnum.bidding:
            let CampIdAndCreatorId = data.split("/")
            let CampId = CampIdAndCreatorId[0]
            let CreatorId = CampIdAndCreatorId[1]
            
            return window.open(`lookwhostalkingapp://bidding/${CampId}/${CreatorId}`)
        
        case DLActionEnum.chat:
            CampIdAndCreatorId = data.split("/")
            CampId = CampIdAndCreatorId[0]
            CreatorId = CampIdAndCreatorId[1]
            return window.open(`lookwhostalkingapp://bidding/${CampId}/${CreatorId}`)
        
        default:
            break;
    }
}

export default DLActionHandler